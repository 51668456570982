<template>
  <div class="pa-2">
    <v-card class="schedule-planner" elevation="12" :loading="loading">
      <v-card-title class="px-3 py-4">
        <v-btn outlined color="primary" @click="$router.go(-1)">
          <v-icon left> mdi-chevron-left </v-icon>
          <span class="caption font-weight-medium"> Back </span>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="title font-weight-black grey--text text--darken-2"> {{ calendarObj.title }} </span>
        <v-spacer></v-spacer>
        <v-btn-toggle
          dense
          background-color="grey"
          dark
          multiple
        >
          <v-btn @click="goTo('prev')">
            <v-icon> mdi-code-less-than </v-icon>
          </v-btn>
          <v-btn @click="goTo('next')">
            <v-icon> mdi-code-greater-than </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-card-title>
      <v-card-text class="pa-3 pt-0">
        <full-calendar ref="fullCalendar" :options="calendarOptions"></full-calendar>
      </v-card-text>
    </v-card>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title class="primary darken-2 white--text pa-4 pb-3">
        <span class="body-1 font-weight-medium"> {{ routineObj._id ? 'Update Routine' : 'Add Routine' }} </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-2 py-1">
        <v-form ref="form">
          <v-container fluid>
            <v-row dense>
              <v-col cols="12">
                <v-text-field dense hide-details outlined label="Class" :value="routineObj?.classTitle" disabled></v-text-field>
              </v-col>
              <v-col cols="8">
                <v-select label="Week day" outlined dense hide-details v-model="routineObj.weekday" :items="weekDays" item-text="name" item-value="value" disabled></v-select>
              </v-col>
              <v-col cols="4" align-self="center" class="px-3">
                <v-checkbox dense hide-details v-model="routineObj.isbreak" label="Is break" class="d-inline-flex"></v-checkbox>
              </v-col>
              <v-col cols="6">
                <v-menu :ref="`startMenu`" v-model="routineObj.menu2" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template #activator="{ on, attrs }">
                    <v-text-field outlined label="From" v-model="routineObj.startat" prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" hide-details dense></v-text-field>
                  </template>
                  <v-time-picker format="24hr" v-if="routineObj.menu2" v-model="routineObj.startat" @click:minute="$refs.startMenu.save(routineObj.startat)" full-width></v-time-picker> 
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :ref="`endMenu`" v-model="routineObj.menu3" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template #activator="{ on, attrs }">
                    <v-text-field outlined label="To" v-model="routineObj.endat" prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" hide-details dense></v-text-field>
                  </template>
                  <v-time-picker format="24hr" :min="routineObj.startat" v-if="routineObj.menu3" v-model="routineObj.endat" full-width  @click:minute="$refs.endMenu.save(routineObj.endat)"></v-time-picker> 
                </v-menu>
              </v-col>
              <template v-if="routineObj.isbreak">
                <v-col cols="12">
                  <v-text-field dense hide-details v-model="routineObj.title" outlined label="Title" :rules="setValidation('requiredValidation')"></v-text-field>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="6">
                  <v-autocomplete :items="listOfSubjects" dense hide-details outlined v-model="routineObj.subject" label="Subject" item-text="name" item-value="_id">
                    <!-- <template #selection="{ item, index }">
                      <v-chip v-if="index === 0" small class="mt-1" :color="($vuetify.theme.dark) ? '' : 'grey darken-2'">
                        <span class="caption white--text"> {{ item.name }} </span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption mt-1">
                        (+{{ routineObj.subjectIds.length - 1 }} others)
                      </span>
                    </template> -->
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    :items="listOfStaffs" dense hide-details outlined v-model="routineObj.staff" label="Staff"
                    item-text="formdata.name" item-value="_id"
                  >
                    <template #item="{ item: staffuser, on, attrs }">
                      <v-list-item v-on="on" v-bind="attrs" class="py-5">
                        <div class="d-flex align-center">
                          <v-avatar left size="30">
                            <v-img
                              :src="`${imageRootUrl}profile/${staffuser.profile}`"
                              height="100%" width="100%"
                            ></v-img>
                          </v-avatar>
                          <span class="mx-3 caption font-weight-medium"> {{ staffuser.formdata.name }} </span>
                        </div>
                      </v-list-item>
                    </template>
                    <template #selection="{ item: staffuser }">
                      <div class="d-flex align-center mt-1">
                        <v-avatar left size="30">
                          <v-img
                            :src="`${imageRootUrl}profile/${staffuser.profile}`"
                            height="100%" width="100%"
                          ></v-img>
                        </v-avatar>
                        <span class="mx-3 caption font-weight-medium"> {{ staffuser.formdata.name }} </span>
                      </div>
                    </template>
                  </v-autocomplete>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-2">
        <v-spacer></v-spacer>
        <v-btn v-if="routineObj._id" tile @click="deleteRoutine" color="error"> Delete </v-btn>
        <v-btn tile @click="saveOrUpdateRoutine" color="success"> {{ routineObj._id ? 'Update' : 'Save' }} </v-btn>
        <v-btn tile @click="closeRoutineDialog" color="primary"> Cancel </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue"
import resourceTimelinePlugin from "@fullcalendar/resource-timeline"
import interactionPlugin from "@fullcalendar/interaction"
export default {
  components: {
    'full-calendar': FullCalendar
  },
  data () {
    return {
      loading: false,
      dialog: false,
      routineObj: {},
      listOfStaffs: [],
      listOfSubjects: [],
      calendarObj: {
        title: this.$moment().format('dddd'),
        date: this.$moment().format('YYYY-MM-DD')
      },
      calendarOptions: {
        headerToolbar: false,
        height: (window.innerHeight - 157),
        resourceAreaWidth: '14%',
        // eventMinHeight: '10%', // default: 15
        // added for load week day alone
        initialDate: this.$moment().format('YYYY-MM-DD'),
        eventMaxStack: 2,
        plugins: [resourceTimelinePlugin, interactionPlugin],
        resourceAreaHeaderContent: 'Class rooms',
        initialView: 'resourceTimeline', // customView
        resourceGroupField: 'courseName',
        views: {
          resourceTimeline: {
            // columnFormat: 'dddd', // Format the day to only show like 'Monday'
            slotDuration: '00:15'
          },
          customView: {
            type: 'resourceTimelineWeek',
            // columnFormat: 'dddd', // Format the day to only show like 'Monday'
            hiddenDays: [0] // Hide Sunday
          }
        },
        resources: [],
        events: [],
        selectable: true,
        select: (args) => {
          this.openRoutineDialog(args)
        },
        eventClick: ({ event }) => {
          this.editRoutineDialog({ ...event.extendedProps })
        },
      },
      listOfClasses: [],
      listOfCourses: []
    }
  },
  mounted () {
    this.$store.dispatch('getCourses').then((data) => {
      this.listOfCourses = data
      this.getListOfClasses()
    })
    window.onresize = () => {
      this.calendarOptions.height = (window.innerHeight - 157)
    }
  },
  methods: {
    goTo (when) {
      if (this.$refs.fullCalendar.getApi()) this.$refs.fullCalendar.getApi()[when]()
      let calendarApi = this.$refs.fullCalendar.getApi()
      if (calendarApi && calendarApi.currentData && calendarApi.currentData.viewTitle) {
        this.calendarObj.title = this.$moment(calendarApi.currentData.viewTitle, 'MMMM D, YYYY').format('dddd')
        this.calendarObj.date = this.$moment(calendarApi.currentData.viewTitle, 'MMMM D, YYYY').format('YYYY-MM-DD')
        this.getRoutinesAsEvents({ weekday: this.$moment(this.calendarObj.title, 'dddd').day() })
      }
    },
    getListOfClasses () {
      this.$_execute('get', 'classes')
        .then(({ data = [] } = {}) => {
          data.map((classItem) => {
            if (classItem.courseId) {
              classItem.course = this.listOfCourses.find(course => course._id === classItem.courseId)
              classItem.name = `${classItem.course ? classItem.course.name : 'N/A'} - ${classItem.section}`
            }
            classItem.title = classItem.name
            classItem.courseName = classItem.course?.name || ''
            classItem.id = classItem._id
          })
          this.listOfClasses = data
          this.$set(this.calendarOptions, 'resources', this.listOfClasses)
          this.getRoutinesAsEvents()
        })
        .finally(() => {
          // get staff list
          this.$store.dispatch('getUsersList', { only: [2], lookupShift: true })
            .then((data) => {
              this.listOfStaffs = data || []
            })
          // get subject list
          this.$store.dispatch('getSubjects')
            .then((subjects) => {
              this.listOfSubjects = subjects || []
            })
        })
    },
    getRoutinesAsEvents ({ weekday } = {}) {
      weekday = weekday || this.$moment(this.calendarObj.date, 'YYYY-MM-DD').day()
      this.$_execute('get', `classes/get-routines?weekday=${weekday}`)
        .then(({ data = [] } = {}) => {
          // load routines as events
          const routines = data.map((routineItem) => ({
            ...routineItem,
            resourceId: routineItem.class,
            title: routineItem.isbreak ? routineItem.title : [routineItem?.subject?.name].filter(x => x).join(' - '),
            start: `${this.calendarObj.date}T${routineItem.startat}:00`,
            end: `${this.calendarObj.date}T${routineItem.endat}:00`
          }))
          this.$set(this.calendarOptions, 'events', routines)
        })
    },
    openRoutineDialog ({ startStr, endStr, resource } = {}) {
      // const { startStr, endStr, resource } = args
      // const date = this.$_formatDate(startStr, 'YYYY-MM-DD', 'YYYY-MM-DD')
      this.routineObj = {
        class: resource?.extendedProps?._id,
        classTitle: resource?.title,
        weekday: (+this.$moment(this.calendarObj.title, 'dddd').format('d')),
        startat: this.$_formatDate(startStr, 'YYYY-MM-DDTHH:mm', 'HH:mm'),
        endat: this.$_formatDate(endStr, 'YYYY-MM-DDTHH:mm', 'HH:mm')
      }
      // console.log({ resource })
      this.dialog = true
    },
    editRoutineDialog (event) {
      this.$_execute('get', `classes/get-routine/${event._id}`).then(({ data }) => {
        data.classTitle = this.listOfClasses.find(x => x._id === data.class)?.name
        this.routineObj = {
          ...data,
          subject: data.subject ? data.subject._id : data.subject
        }
        this.dialog = true
      })
    },
    saveOrUpdateRoutine () {
      const method = this.routineObj._id ? 'put' : 'post'
      const url = (this.routineObj._id) ? `classes/update-routine/${this.routineObj._id}` : 'classes/add-routine'
      this.$_execute(method, url, this.routineObj).then(() => {
        this.getRoutinesAsEvents()
      }).finally(() => {
        this.dialog = false
      })
    },
    deleteRoutine () {
      this.$root.$emit('delete', {
        module: 'classes/delete-routine',
        _id: this.routineObj._id,
        onResolve: () => {
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getRoutinesAsEvents()
        },
        onComplete: () => {
          this.dialog = false
        }
      })
    },
    closeRoutineDialog () {
      this.routineObj = {}
      this.dialog = false
    }
  }
}
</script>
<style>
  .fc-icon-chevron-left:before {
    vertical-align: top;
  }
  .fc-icon-chevron-right:before {
    vertical-align: top;
  }
  .schedule-planner .fc-license-message {
    display: none !important;
  }
  .schedule-planner table tr div.fc-scroller {
    overflow: auto !important;
  }
  .schedule-planner * {
    color: rgb(99, 99, 99) !important;
  }
</style>
